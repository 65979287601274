import React from 'react'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import FormDuuf from 'components/related/Form'
import { FormCSS } from 'styles/FormStyle'
import * as Yup from 'yup'
import WhiteMail from 'img/white-mail.inline.svg'
import WhitePhone from 'img/white-phone.inline.svg'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

interface BannerContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  pageContext?: any
}

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}
`

const StyledBannerContact = styled.section``

const CustomContainer = styled.div`
  @media (min-width: 1400px) {
    max-width: 1450px !important;
  }
`

const Content = styled(ParseContent)`
  padding-top: 55px;
  color: ${(props) => props.theme.color.light};

  & h1,
  h2,
  h3,
  h4 {
    color: ${({ theme }) => theme.color.light};
  }

  & p {
    & b,
    strong {
      font-size: 20px;
      line-height: 36px;
    }

    line-height: 30px;
  }

  @media (max-width: 991px) {
    padding-top: 25px;
  }
`

const Banner = styled.div`
  padding: 90px 60px;
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 10px;

  @media (max-width: 1199px) {
    padding: 50px 40px;
  }

  @media (max-width: 991px) {
    padding: 60px 30px 15px 30px;
  }

  @media (max-width: 575px) {
    padding: 30px 15px 15px 15px;
  }
`

const StyledBreadcrumb = styled.div`
  nav {
    margin: 0;
  }

  ol {
    display: flex;
    padding: 0;
    color: ${({ theme }) => theme.color.light};

    li {
      list-style: none;

      a {
        color: ${({ theme }) => theme.color.light};

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .breadcrumb__separator {
      margin: 0 10px;
    }
  }
`

const LinkLabel = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.light};

  &:hover {
    text-decoration: underline;
  }
`

const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 60px;
`

const BannerContact: React.FC<BannerContactProps> = ({
  fields,
  pageContext,
}) => (
  <StyledBannerContact>
    <CustomContainer className="container">
      <Banner className="position-relative">
        <div className="row">
          <div className="col-lg-6">
            <StyledBreadcrumb>
              <Breadcrumb
                crumbs={pageContext?.breadcrumb.crumbs}
                crumbSeparator=">"
              />
            </StyledBreadcrumb>
            <Content content={fields.description || ''} />
            <div className="mb-4 mt-4">
              <a
                className="d-flex align-items-center"
                href={fields.phone?.url || ''}
              >
                <SvgWrapper>
                  <WhitePhone />
                </SvgWrapper>
                <LinkLabel>{fields.phone?.title}</LinkLabel>
              </a>
            </div>
            <div>
              <a
                className="d-flex align-items-center"
                href={fields.mail?.url || ''}
              >
                <SvgWrapper>
                  <WhiteMail />
                </SvgWrapper>
                <LinkLabel>{fields.mail?.title}</LinkLabel>
              </a>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <StyledFormDuuf
              id={1}
              className="mt-5"
              privacyUrlPlacement="privacyverklaring"
              privacyUrl="/privacyverklaring"
              yupSchema={{
                input_6: Yup.string()
                  .min(10, 'Moet 10 tekens zijn')
                  .max(10, 'Moet 10 tekens zijn'),
              }}
            />
          </div>
        </div>
      </Banner>
    </CustomContainer>
  </StyledBannerContact>
)

export default BannerContact
